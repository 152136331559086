import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { deleteArticle, getAllArticles } from "../../api/BackFunctions";
import ImageList from "@mui/material/ImageList";
import InfiniteScroll from "react-infinite-scroll-component";
import Article from "./Article";
import PopupValidation from "../Share/PopupValidation";
import MessageToShowAfterRequest from "../Share/MessageToShowAfterRequest";

const ListArticles = () => {
  const [listFiltered, setListFiltered] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [valuesPopup, setValuesPopup] = useState({
    isOpen: false,
    validate: () => {},
    message: "",
  });
  const [messageIsOpen, setMessageIsOpen] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    callGetAllArticle(page);
  }, []);

  const callGetAllArticle = (newPage, isReload = false) => {
    setIsLoading(true);
    getAllArticles(newPage)
      .then((res) => {
        isReload
          ? setListFiltered([...res.result])
          : setListFiltered([...listFiltered, ...res.result]);
        setPage(newPage);
        setHasMore(res.result.length > 0);
      })
      .finally(() => setIsLoading(false));
  };

  const openPopupToConfirm = (id) => {
    setValuesPopup({
      isOpen: true,
      validate: () => deleteProduct(id),
      message: "Voulez vous vraiment Supprimer cet article?",
    });
  };

  const closePopup = () => {
    setValuesPopup({ isOpen: false, validate: () => {}, message: "" });
  };

  const closeMessage = () => {
    setMessageIsOpen(false);
  };

  const deleteProduct = (id) => {
    deleteArticle(id)
      .then((res) => {
        setMessageIsOpen(true);
        setStatus(res.data.status);
        callGetAllArticle(0, true);
      })
      .catch((err) => {
        setStatus("error");
      });
  };

  // TODOOO
  //filtrer la liste

  return (
    <Container maxWidth="md" style={{ position: "relative" }}>
      <InfiniteScroll
        dataLength={listFiltered.length}
        next={() => callGetAllArticle(page + 1)}
        hasMore={hasMore}
        loader={isLoading && <div>Loading...</div>}
      >
        <ImageList
          sx={{ height: "100%" }}
          cols={window.innerWidth > 800 ? 3 : 1}
        >
          {listFiltered.length > 0
            ? listFiltered.map((elem, i) => (
                <Article
                  key={i + elem.name}
                  info={elem}
                  deleteProduct={openPopupToConfirm}
                />
              ))
            : "la base de données est vide"}
        </ImageList>
      </InfiniteScroll>
      <PopupValidation
        isOpen={valuesPopup.isOpen}
        validation={valuesPopup.validate}
        close={closePopup}
        message={valuesPopup.message}
      />
      {messageIsOpen ? (
        <MessageToShowAfterRequest close={closeMessage} status={status} />
      ) : (
        ""
      )}
    </Container>
  );
};

export default ListArticles;
