import axios from "axios";

const ENDPOINT_BASE = "https://destock-api.be/v1";
//const ENDPOINT_BASE = "http://localhost:8080/destock/api/v1";

const ENDPOINT_ARTICLES = ENDPOINT_BASE + "/articles";
const ENDPOINT_CATEGORIES = ENDPOINT_BASE + "/categories";

export const getAllArticles = async (page) => {
  return axios
    .get(`${ENDPOINT_ARTICLES}/getAllArticles/${page}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log("erreur dans la récuperation des article");
    });
};

export const getArticleById = async (id) => {
  try {
    const response = await axios({
      method: "get",
      url: ENDPOINT_ARTICLES + "/getById/" + id,
      data: {},
    });
    return response.data;
  } catch (err) {
    console.log(
      "error requete lors de la récupération de l'article par id: ",
      err
    );
  }
};

export const getArticleByCategory = async (category, max) => {
  try {
    const response = await axios({
      method: "get",
      url: `${ENDPOINT_ARTICLES}/getByCategory/${category}/${max} `,
      data: {},
    });
    return response.data;
  } catch (err) {
    console.log("error lors de la récupération par category : ", err);
  }
};

export const addArticles = (data) => {
  return axios.post(ENDPOINT_ARTICLES, data);
};

export const deleteArticle = (id) => {
  return axios.delete(`${ENDPOINT_ARTICLES}/deleteArticle/${id}`);
};

export const getAllCategories = async () => {
  return axios
    .get(`${ENDPOINT_CATEGORIES}/getAllCategories`)
    .then((res) => res.data)
    .catch((err) => {
      console.log("erreur dans la récuperation des categories");
    });
};

export const addCategory = async (data) => {
  return axios.post(`${ENDPOINT_CATEGORIES}/addCategory`, data);
};
