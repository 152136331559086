import { useEffect, useState } from "react";
import { categoryMenu } from "./utils/variables";
import TabNav from "./components/homeComponent";
import "./App.css";
import LoginForm from "./components/LoginForm";
import { isConnected, logOut } from "./api/apiFirebase";
import MessageToShowAfterRequest from "./components/Share/MessageToShowAfterRequest";
import LogoutIcon from "@mui/icons-material/Logout";
import { iconStyle } from "./components/Style/global";

function App() {
  const [selected, setSelected] = useState(categoryMenu[0]);
  const [isUserConnected, setIsUserConnected] = useState(false);
  const [valuesMessage, setValuesMessage] = useState({
    isOpen: false,
    status: "",
  });

  useEffect(() => {
    checkIsConnected();

    return () => {};
  }, []);

  const checkIsConnected = (from) => {
    isConnected().then((res) => {
      setIsUserConnected(res);
      const status =
        (res && from === "connexion") || (!res && from === "deconnexion")
          ? "success"
          : "error";

      from &&
        setValuesMessage({
          isOpen: true,
          status,
        });
    });
  };

  const logOutUser = () => {
    logOut()
      .then(() => {
        checkIsConnected("deconnexion");
      })
      .catch((err) => {
        setValuesMessage({ isOpen: true, status: "error" });
      });
  };

  return (
    <div style={{ position: "relative" }} className="App">
      <h1>Destock</h1>
      {isUserConnected ? (
        <span style={{ ...iconStyle, right: "3rem" }} onClick={logOutUser}>
          <LogoutIcon sx={{ color: "rgba(255, 255, 255, 0.54)" }} />
        </span>
      ) : (
        ""
      )}
      {isUserConnected ? (
        <>
          <TabNav selected={selected} setSelected={setSelected} />
          {<selected.component />}
        </>
      ) : (
        <LoginForm connexion={() => checkIsConnected("connexion")} />
      )}
      {valuesMessage.isOpen ? (
        <MessageToShowAfterRequest
          close={() => setValuesMessage({ isOpen: false, status: "" })}
          status={valuesMessage.status}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default App;
