import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Switch,
  InputLabel,
  Box,
} from "@material-ui/core";

import { addCategory } from "../../api/BackFunctions";
import MessageToShowAfterRequest from "../Share/MessageToShowAfterRequest";
import { buttonStyle } from "../Style/global";

const FormAddCategory = () => {
  const [values, setValues] = useState({
    name: "",
    isTemporary: false,
  });
  const [valueForPopup, setValueForPopup] = useState({
    isOpen: false,
    status: "",
  });

  const handleChange = (e) => {
    const { target } = e;
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const handleChangeChecked = (e) => {
    const { target } = e;
    setValues({
      ...values,
      [target.name]: target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    for (const property in values) {
      const value = values[property];
      formData.append(property, value);
    }

    addCategory(formData)
      .then((res) => {
        setValueForPopup({
          isOpen: true,
          status: res.data.status,
        });
      })
      .catch((err) => console.log("erreur au moment de l ajout de cat", err));
  };

  const initialValues = () => {
    setValues({
      name: "",
      isTemporary: false,
    });
  };

  const closeMessage = () => {
    setValueForPopup({ isOpen: false, status: "" });
  };

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit}>
        <TextField
          name="name"
          label="Nom de la catégorie"
          value={values.name}
          onChange={handleChange}
          required
          fullWidth
        />

        <Box display={"flex"} marginTop="15px">
          <InputLabel id="isTemporary">catégorie temporaire ? </InputLabel>

          <Switch
            name="isTemporary"
            checked={values.isTemporary}
            onChange={handleChangeChecked}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        <Button
          centerRipple
          variant="contained"
          color="primary"
          type="submit"
          style={buttonStyle}
        >
          Envoyer
        </Button>
      </form>
      {valueForPopup.isOpen ? (
        <MessageToShowAfterRequest
          status={valueForPopup.status}
          close={closeMessage}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default FormAddCategory;
