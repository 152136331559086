import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";

const MessageToShowAfterRequest = ({ status = "", close = () => {} }) => {
  useEffect(() => {
    setTimeout(() => {
      close();
    }, 2000);
  }, []);

  const styleAlert = {
    position: "fixed",
    top: "2rem",
    right: "2rem",
    width: "15rem",
  };
  return status === "success" ? (
    <Alert style={styleAlert} severity="success">
      Tout s'est bien passé!
    </Alert>
  ) : (
    <Alert style={styleAlert} severity="error">
      Une erreur s'est produite!
    </Alert>
  );
};

export default MessageToShowAfterRequest;
