import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { categoryMenu } from "../../utils/variables";

export default function TabNav({ selected, setSelected }) {
  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  const listTab = categoryMenu.map((elem, i) => (
    <Tab
      key={elem.name + i}
      value={elem}
      label={elem.name}
      //style={{ padding: "12px 30px" }}
    />
  ));

  return (
    <Box>
      <Tabs
        value={selected}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="categoryMenu"
        centered
        variant={window.innerWidth < 420 ? "scrollable" : "standard"}
        scrollButtons="auto"
      >
        {listTab}
      </Tabs>
    </Box>
  );
}
