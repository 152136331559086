import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 700 ? 400 : 200,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PopupValidation = ({
  isOpen = false,
  validation = () => {},
  close = () => {},
  message = "Voulez vous vraiment confirmer ?",
}) => {
  const _valide = () => {
    validation();
    close();
  };

  return (
    <Modal
      open={isOpen}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {message}
        </Typography>
        <div className="modal-actions">
          <Button onClick={close}>Annuler</Button>
          <Button onClick={_valide}>Valider</Button>
        </div>
        {/* ici mettre 2 boutons , valider / annuler */}
      </Box>
    </Modal>
  );
};

export default PopupValidation;
