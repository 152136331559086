// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

//TODO: Add SDKs for Firebase products that you want to use
//https://firebase.google.com/docs/web/setup#available-libraries

//Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDE8jqIM7GHLv7q-JVSrVYEGzEy6tskgTk",
  authDomain: "destock-f922d.firebaseapp.com",
  projectId: "destock-f922d",
  storageBucket: "destock-f922d.appspot.com",
  messagingSenderId: "317108483779",
  appId: "1:317108483779:web:06c09d81d9974a61ee1476",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();

export const login = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password).catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
};

export const logOut = () => {
  return new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const isConnected = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user);
      } else {
        resolve(false);
      }
    });
  });
};
