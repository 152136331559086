import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Switch,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
} from "@material-ui/core";
import { Category } from "../../utils/variables";
import ZoneDragDrop from "./ZoneDragDrop";
import { addArticles, getAllCategories } from "../../api/BackFunctions";
import MessageToShowAfterRequest from "../Share/MessageToShowAfterRequest";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { buttonStyle } from "../Style/global";

const FormAddArticle = () => {
  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState({
    name: "",
    price: "",
    description: "",
    truePrice: "",
    isInStock: true,
    category: "",
    isLittleStock: false,
    images: [],
  });
  const [valueForPopup, setValueForPopup] = useState({
    isOpen: false,
    status: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllCategories().then((res) => {
      return setCategories(res.result);
    });
  }, []);

  const handleChange = (e) => {
    const { target } = e;
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const handleChangeChecked = (e) => {
    const { target } = e;
    setValues({
      ...values,
      [target.name]: target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();

    for (const property in values) {
      const value = values[property];
      if (!Array.isArray(value)) {
        const newValue = property === "truePrice" && value === "" ? -1 : value;

        formData.append(property, newValue);
      } else {
        for (let i = 0; i < value.length; i++) {
          formData.append(property, value[i], value[i].name);
        }
      }
    }

    addArticles(formData)
      .then((res) => {
        setValueForPopup({
          isOpen: true,
          status: res.data.status,
        });
        setIsLoading(false);
      })
      .catch((err) =>
        console.log("erreur au moment de l ajout de produits", err)
      );
  };

  const initialValues = () => {
    setValues({
      ...values,
      name: "",
      price: "",
      description: "",
      truePrice: "",
      isInStock: true,
      category: Category[0].id,
      isLittleStock: false,
    });
  };

  const closeMessage = () => {
    setValueForPopup({ isOpen: false, status: "" });
  };

  const listItem = categories.map((item, i) => (
    <MenuItem key={item.name + i + "cat"} value={item.id}>
      {item.name}
    </MenuItem>
  ));

  return (
    <Container maxWidth="md">
      <Backdrop open={isLoading} style={{ zIndex: 25 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit}>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth required>
            <InputLabel id="category">Catégorie</InputLabel>
            <Select
              name="category"
              labelId="category"
              value={values.category}
              label="Categorie"
              onChange={handleChange}
            >
              {listItem}
            </Select>
          </FormControl>
        </Box>

        <TextField
          name="name"
          label="Nom de l'article"
          value={values.name}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          name="price"
          label="Notre prix"
          type="number"
          value={values.price}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          name="truePrice"
          label="Prix de base"
          value={values.truePrice}
          type="number"
          onChange={handleChange}
          fullWidth
        />
        <TextField
          name="description"
          label="Description de l'article"
          value={values.description}
          onChange={handleChange}
          multiline
          fullWidth
        />
        <Box display={"flex"} marginTop="15px">
          <InputLabel id="isInStock">Y a t'il du stock</InputLabel>
          <Switch
            name="isInStock"
            checked={values.isInStock}
            onChange={handleChangeChecked}
            inputProps={{ "aria-label": "controlled" }}
          />
          <InputLabel id="isInStock">Y a t'il un peu de stock</InputLabel>
          <Switch
            name="isLittleStock"
            checked={values.isLittleStock}
            onChange={handleChangeChecked}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        <ZoneDragDrop images={values.images} setImages={handleChange} />
        <Button
          centerRipple
          variant="contained"
          color="primary"
          type="submit"
          disabled={values.images.length === 0 || isLoading}
          style={buttonStyle}
        >
          Envoyer
        </Button>
      </form>

      {valueForPopup.isOpen ? (
        <MessageToShowAfterRequest
          status={valueForPopup.status}
          close={closeMessage}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default FormAddArticle;
