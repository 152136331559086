import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import { _newObj } from "../../utils/functions";

const useStyles = makeStyles({
  dropzone: {},
  previewContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    height: "400px",
    margin: 0,
    overflowY: "scroll",
  },
  previewItem: {
    width: "100px",
    height: "100px",
    marginTop: "30px",
    position: "relative",
  },
  previewImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "5px",
  },
});

const ZoneDragDrop = ({ setImages, images }) => {
  const classes = useStyles();
  const LimitFiles = 5;

  const handleChange = (files) => {
    const newObj = _newObj(files);
    setImages(newObj);
  };

  return (
    <DropzoneArea
      acceptedFiles={["image/*"]}
      dropzoneText={
        images.length === 0
          ? "Glissez et déposez vos images ici, ou cliquez pour sélectionner des fichiers*"
          : ""
      }
      onChange={handleChange}
      filesLimit={LimitFiles}
      previewGridClasses={{
        container: classes.previewContainer,
        item: classes.previewItem,
        image: classes.previewImag,
      }}
      Icon={images.length !== LimitFiles ? null : "cloud_upload"}
      getFileAddedMessage={(filename) =>
        `l'image ${filename} a bien été ajoutée `
      }
      getFileRemovedMessage={(filename) =>
        `l'image ${filename} a bien été supprimé`
      }
      getFileLimitExceedMessage={() =>
        `Vous ne pouvez pas ajouter plus de ${LimitFiles} images`
      }
      getDropRejectMessage={(filename) =>
        `La taille du fichier ${filename} est trop haute`
      }
    />
  );
};
export default ZoneDragDrop;
