import FormAddArticle from "../components/FormAddArticle";
import FormAddCategory from "../components/FormAddCategory";
import ListArticles from "../components/ListArticles";

export const categoryMenu = [
  {
    name: "Ajouter articles",
    message: "Ajouter un produit",
    component: FormAddArticle,
  },
  {
    name: "Ajouter catégorie",
    message: "Ajouter une nouvelle catégorie",
    component: FormAddCategory,
  },
  { name: "Afficher", message: "Afficher la liste", component: ListArticles },
];

export const Category = [
  "jouet",
  "alimentaire",
  "décoration",
  "textile",
  "éléctronique",
  "produits ménagers",
  "divers",
];
