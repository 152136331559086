import { ImageListItem, ImageListItemBar } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { iconStyle } from "../Style/global";

const Article = ({ info, deleteProduct }) => {
  const { id, name, url, price } = info;

  return (
    <ImageListItem
      style={{ position: "relative", height: "200px", overflow: "hidden" }}
    >
      <img src={`${url}`} alt={name} loading="lazy" />
      <ImageListItemBar title={name} />
      <span
        style={{ ...iconStyle, right: "1rem" }}
        onClick={() => deleteProduct(id)}
      >
        <DeleteForeverIcon sx={{ color: "rgba(255, 255, 255, 0.54)" }} />
      </span>
      <span style={{ ...iconStyle, left: "1rem" }} onClick={() => {}}>
        <EditIcon sx={{ color: "rgba(255, 255, 255, 0.54)" }} />
      </span>
    </ImageListItem>
  );
};

export default Article;
