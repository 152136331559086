export const buttonStyle = { marginTop: "2rem", marginBottom: "5rem" };

export const iconStyle = {
  position: "absolute",
  top: "1rem",
  width: "2rem",
  height: "2rem",
  backgroundColor: "rgba(0, 0, 0, 0.54)",
  borderRadius: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};
