import { Button, Container, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { buttonStyle } from "../Style/global";
import { login } from "../../api/apiFirebase";

const LoginForm = ({ connexion }) => {
  const [values, setValues] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    const { target } = e;
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login(values.email, values.password).then(() => connexion());
  };
  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <TextField
          name="email"
          label="Email"
          value={values.email}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          name="password"
          label="Mot de passe"
          value={values.password}
          onChange={handleChange}
          type="password"
          required
          fullWidth
        />

        <Button
          centerRipple
          variant="contained"
          color="primary"
          type="submit"
          style={buttonStyle}
        >
          Envoyer
        </Button>
      </form>
    </Container>
  );
};

export default LoginForm;
